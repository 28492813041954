import React, { useRef, useEffect, createRef } from 'react';
import { graphql, Link } from 'gatsby';
import { siteMetadata } from '../../gatsby-config';
import { SEO } from '../components/SEO';
import Layout from '../components/layout';
import Testimonial from '../components/testimonial';
import CourseTopicSelector from '../components/courseTopicSelector';
import HighlightedClassTrainings from '../components/highlightedClassTrainings';
import ServiceWidebox from '../components/ServiceWidebox';
import logo from '../images/logo_text.png';
import { getServiceItemImageName, getKeyFromString } from '../utils/utils.js';

export default props => {
  const pageData = props.data.allFrontpageJson.edges[0].node;

  return (
    <>
      <SEO
        title="Excel, Robot Framework ja prosessien automatisointiin liittyvä koulutus ja konsultointi | Helsinki, Espoo, Vantaa"
        description="Tutustu Officekoulutus Vision tehokkaisiin Excel-kursseihin ja muihin tietotekniikkakoulutuksiin. Tule kurssille tai tilaa yrityksellesi räätälöity koulutus."
      />
      <Layout
        // Voisiko nämä kerätä jossain muualla
        // Liikaa yksityiskohtaa tällä abstraktion tasolla
        // ...props tyylisesti
        additionalClassForWrapper="front-bg"
        headerBgStyles={{
          //background: 'url(' + bg + ')',
          height: '448px',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
        displayPushToBottom={true}
        headerNavLinkStyle={{ color: 'white' }}
        mainTitle={'Räätälöityä IT-koulutusta ja konsultointia'}
        additionalTitle={'Työelämän tarpeisiin'}
        headerTitleStyle={{ color: 'white' }}
        headerAdditionalTitleStyle={{
          color: 'white',
          fontFamily: 'Raleway',
          fontSize: '36px',
        }}
        additionalTitleClassNames={'mb-4 mt-md-0 mb-md-4'}
        logo={logo}
        {...props}
      >
        <CourseTopicSelector topics={pageData.info_at_top.topics} />
        <HighlightedClassTrainings
          courses={props.data.allClassTrainingsJson.nodes}
          style={{ background: '#019999' }}
        />
        <Testimonial
          quote={pageData.testimonials.vr.quote}
          person={pageData.testimonials.vr.person}
          company={pageData.testimonials.vr.company}
        />
        <ServiceWidebox
          title={pageData.services.online.title}
          description={pageData.services.online.description}
          link={pageData.services.online.link}
          cta={pageData.services.online.cta}
          style={{ background: '#019999' }}
        />
        <Testimonial
          quote={pageData.testimonials.pohjolarakennus.quote}
          person={pageData.testimonials.pohjolarakennus.person}
          company={pageData.testimonials.pohjolarakennus.company}
        />
        <ServiceWidebox
          title={pageData.services.consultancy.title}
          description={pageData.services.consultancy.description}
          link={pageData.services.consultancy.link}
          cta={pageData.services.consultancy.cta}
          style={{ background: '#019999' }}
        />
        <Testimonial
          quote={pageData.testimonials.laaka.quote}
          person={pageData.testimonials.laaka.person}
          company={pageData.testimonials.laaka.company}
        />
        <ServiceWidebox
          title={pageData.services.excel.title}
          description={pageData.services.excel.description}
          link={pageData.services.excel.link}
          cta={pageData.services.excel.cta}
          style={{ background: '#019999' }}
        />
      </Layout>
    </>
  );
};

export const query = graphql`
  query FrontpageQuery {
    allClassTrainingsJson {
      nodes {
        price
        signup_iframe
        date
        course_id
        title
        description
        cta
        link
        active
      }
    }
    allFrontpageJson {
      edges {
        node {
          info_at_top {
            topics {
              link
              title
            }
          }
          services {
            excel {
              cta
              description
              link
              title
            }
            consultancy {
              cta
              description
              link
              title
            }
            online {
              cta
              description
              link
              title
            }
          }
          testimonials {
            laaka {
              company
              person
              quote
            }
            vr {
              company
              person
              quote
              title
            }
            pohjolarakennus {
              company
              person
              quote
            }
          }
        }
      }
    }
  }
`;
