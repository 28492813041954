import React from 'react';
import { Link } from 'gatsby';

export default function HighlightedClassTrainings(props) {
  const n = props.courses.reduce((numberOfActiveCourses, current) => {
    if (current.active) {
      numberOfActiveCourses = numberOfActiveCourses + 1;
    }
    return numberOfActiveCourses;
  }, 0);
  if (n > 0) {
    // At least one 'Avoin kurssi' is active
    return (
      <div
        className="widebox service service-{class} text-center justify-content-between py-5 px-5"
        style={props.style}
      >
        <h2 className="h2 title mb-5">Seuraavat avoimet koulutukset</h2>
        <div className="row">
          {props.courses.map((course, index) => {
            if (course.active) {
              return (
                <div
                  key={index}
                  className={
                    (n === 1 ? 'col-md-12' : 'col-md-6') +
                    ' text-center mb-md-0' +
                    (index === 0 ? ' mb-5' : '')
                  }
                >
                  <h3 className="title mt-2 mb-3">{course.title}</h3>
                  <div className="date-and-time mb-3">
                    {course.date}, Helsinki
                  </div>
                  {/* <div
                className="description mt-3 mb-4"
                dangerouslySetInnerHTML={{
                  __html: course.description,
                }}
              ></div> */}
                  <Link className="btn cta-button-x" to={'/' + course.link}>
                    {course.cta}
                  </Link>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  }
  return null;
}
