import React from 'react';
import { Link } from 'gatsby';

export default function ServiceWidebox(props) {
  const { titleClasses = 'title mt-5 mb-5' } = props;
  const { titleStyle = { color: 'white' } } = props;
  return (
    <div
      className="container-fluid widebox service service-{class} pb-5"
      style={props.style}
    >
      <div className="row flex-nowrap justify-content-between align-items-end">
        <div className="col-sm-12 text-center">
          <h2 className={titleClasses} style={titleStyle}>
            {props.title}
          </h2>
          {props.theme === 'light' ? (
            <div className="description mb-2">{props.children}</div>
          ) : (
            <>
              <div
                className="description mb-5"
                dangerouslySetInnerHTML={{
                  __html: props.description,
                }}
              ></div>
              <Link className="btn cta-button-x" to={'/' + props.link}>
                {props.cta}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
