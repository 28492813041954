import React from 'react';

export default props => {
  return (
    <div className="testimonial testimonial-x pb-5 px-5">
      <div className="row">
        <div className="col-sm-12 text-center">
          <div className="quote-icon"></div>
          <h2 className="quote">{props.quote}</h2>
          <div className="person">{props.person}</div>
          {props.company ? (
            <div className="company">{props.company}</div>
          ) : (
            undefined
          )}
        </div>
      </div>
    </div>
  );
};
